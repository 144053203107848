// extracted by mini-css-extract-plugin
export var About = "About-module--About--PgVsf";
export var blockquote = "About-module--blockquote--x5QLE";
export var circle = "About-module--circle--aKTsB";
export var container = "About-module--container--dIkcQ";
export var finance = "About-module--finance--ympDU";
export var h4 = "About-module--h4--LqaZB";
export var how = "About-module--how--YpVIZ";
export var howTo = "About-module--howTo--Um+Bo";
export var imageContainer = "About-module--imageContainer--JrHsJ";
export var info = "About-module--info--dUZXf";
export var infoContainer = "About-module--infoContainer--DX-04";
export var line = "About-module--line--G+gZ3";
export var privacy = "About-module--privacy--P0jT3";
export var security = "About-module--security--zIRsE";
export var securityAndPrivacy = "About-module--securityAndPrivacy--NDCKG";
export var what = "About-module--what--joPCZ";
export var why = "About-module--why--ujfbR";