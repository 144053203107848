import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import * as styles from '../css/pages/About.module.scss';

import SEO from '../components/layout/SEO';
import Layout from '../components/layout/Layout';

const About = () => {
    const data = useStaticQuery(graphql`
        {
            allFile(
                filter: {
                    extension: { regex: "/(jpg)|(png)|(jpeg)/" }
                    relativeDirectory: { eq: "about" }
                }
                sort: { fields: relativePath }
            ) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
            }
            currentBuildDate {
                currentDate
            }
        }
    `);

    const schemaData = {
        '@context': 'https://schema.org',
        '@graph': [
            {
                '@type': 'Organization',
                '@id': 'https://www.joinripples.org/#organization',
                name: 'Ripples',
                url: 'https://www.joinripples.org/',
                sameAs: [],
                logo: {
                    '@type': 'ImageObject',
                    '@id': 'https://www.joinripples.org/#logo',
                    url: 'https://roundups-marketing-site.s3.eu-west-1.amazonaws.com/images/ripples-icon-white-bg.png',
                    width: 500,
                    height: 500,
                    caption: 'Ripples',
                },
                image: {
                    '@id': 'https://www.joinripples.org/#logo',
                },
            },
            {
                '@type': 'WebSite',
                '@id': 'https://www.joinripples.org/#website',
                url: 'https://www.joinripples.org/',
                name: 'Ripples',
                description: 'Donate pennies as you spend',
                publisher: {
                    '@id': 'https://www.joinripples.org/#organization',
                },
            },
            {
                '@type': 'WebPage',
                '@id': 'https://www.joinripples.org/about/#webpage',
                url: 'https://www.joinripples.org/about/',
                inLanguage: 'en-GB',
                name: 'About | Ripples',
                isPartOf: {
                    '@id': 'https://www.joinripples.org/#website',
                },
                datePublished: '2019-09-10T18:23:13+00:00',
                dateModified: data.currentBuildDate.currentDate,
            },
        ],
    };

    const howImg1 = data.allFile.edges[0].node.childImageSharp.gatsbyImageData;
    const howImg2 = data.allFile.edges[1].node.childImageSharp.gatsbyImageData;
    const howImg3 = data.allFile.edges[2].node.childImageSharp.gatsbyImageData;

    return (
        <>
            <SEO
                title="About"
                description="Understand what we do at Ripples, how we do it and most importantly why. At Ripples we always prioritise your privacy and security above all else"
                path="/about/"
                schemaData={[schemaData]}
            />
            <Layout mainClass={styles.About}>
                <section className={styles.what}>
                    <div className={styles.container}>
                        <h1>What we do</h1>
                        <hr />
                        <p>
                            We round up your card payments, donate the pennies
                            to your chosen not-for-profit and report back on
                            your amazing impact
                        </p>
                        <p>
                            If all of us activate Ripples,{' '}
                            <strong>together we can raise millions</strong> for
                            charities, schools and important causes around the
                            world
                        </p>
                        <ul>
                            <li>
                                ➜ Round your payments up to 10p, 50p or £1: it’s
                                your choice
                            </li>
                            <li>➜ You can set a weekly cap</li>
                            <li>➜ You choose the cause</li>
                        </ul>
                    </div>
                </section>
                <section className={styles.why}>
                    <div className={styles.container}>
                        <h1>Why we do it</h1>
                        <hr />
                        <p>
                            We’re on a mission to make automatic penny donations
                            mainstream
                        </p>
                        <div className={styles.blockquote}>
                            <h1>
                                <span>Small acts, when</span> multiplied by
                                millions <span>of people, can</span> transform
                                the world
                            </h1>
                            <p className={styles.h4}>&mdash;Howard Zinn</p>
                        </div>
                    </div>
                </section>
                <section className={styles.how}>
                    <div className={styles.container}>
                        <h1>How we do it</h1>
                        <hr />
                        <p>
                            Here’s the short version. For a detailed
                            explanation, see our <Link to="/faqs/">FAQs</Link>.
                        </p>
                        <div className={styles.howTo}>
                            <div className={styles.circle}>
                                <div className={styles.imageContainer}>
                                    <GatsbyImage
                                        image={howImg1}
                                        alt="Handshake icon"
                                    />
                                    <span className={styles.line} />
                                </div>
                            </div>
                            <div className={styles.circle}>
                                <div className={styles.imageContainer}>
                                    <GatsbyImage
                                        image={howImg2}
                                        alt="Cogs working icon"
                                    />
                                    <span className={styles.line} />
                                </div>
                            </div>
                            <div className={styles.circle}>
                                <div className={styles.imageContainer}>
                                    <GatsbyImage
                                        image={howImg3}
                                        alt="Bank card icon"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.infoContainer}>
                            <div className={styles.info}>
                                <h2>
                                    Work with
                                    <br />
                                    your bank
                                </h2>
                                <p>
                                    With your permission, we safely and securely
                                    integrate with your online banking or credit
                                    card provider
                                </p>
                            </div>
                            <div className={styles.info}>
                                <h2>
                                    Calculate
                                    <br />
                                    round ups
                                </h2>
                                <p>
                                    Our app uses your transaction data to
                                    calculate your round ups as you spend
                                </p>
                            </div>
                            <div className={styles.info}>
                                <h2>
                                    Debit
                                    <br />
                                    donations
                                </h2>
                                <p>
                                    When your round ups accumulate to
                                    &pound;5.00, we initiate a &pound;5.00
                                    donation from your account
                                </p>
                            </div>
                        </div>
                        <div className={styles.finance}>
                            <h3>Does Ripples make money?</h3>
                            <p>Yes, just enough to provide the service!</p>
                            <p>
                                Roundups Fundraising Services Ltd is a Social
                                Enterprise. We have a social impact mission that
                                is legally written into the structure of our
                                company. Ripples involves complicated technology
                                and we do have costs to cover.{' '}
                                <strong>5% of all user donations</strong>{' '}
                                currently go towards sustaining the Ripples
                                platform. This covers unavoidable expenses like
                                payment processing fees, app maintenance, staff
                                and promotional activity which, in turn, helps
                                to build the community and facilitate even more
                                penny donations!
                            </p>
                            <p>
                                <strong>
                                    We plan to reduce the 5% admin fee as our
                                    user base increases
                                </strong>
                            </p>
                        </div>
                    </div>
                </section>
                <section
                    id="security&privacy"
                    className={styles.securityAndPrivacy}>
                    <h3>Security &amp; privacy</h3>
                    <hr />
                    <div className={styles.container}>
                        <div className={styles.security}>
                            <h4>Bank level security</h4>
                            <p>
                                We work within the Open Banking Regulations and
                                with major FinTechs to ensure that your banking
                                and personal information is always safe and
                                secure. Find out more about how we are regulated
                                and how we use Open Banking APIs here.
                            </p>
                        </div>
                        <div className={styles.privacy}>
                            <h4>Data privacy</h4>
                            <p>
                                We do not store or share your data with any
                                third party. Your transaction data is not
                                accessible by anyone but you. Our technology
                                only reads the transaction data in order to
                                calculate the roundup figure to debit.
                            </p>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default About;
